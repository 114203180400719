<template>
  <div class="pc_navigtion">
    <div class="dise" v-if="!mask"></div>
    <div class="masks" v-if="mask"></div>
    <img class="pc_logo" src="~img/indexImg/logo.png" v-if="!mask" @click="$router.push({ path: '/index' })" />
    <img class="pc_logo" src="~img/indexImg/26.png" v-if="mask" @click="$router.push({ path: '/index' })" />
    <!-- :class="{ ul_animation: Animation }" -->
    <ul class="ul_one" v-show="showtitle">
      <li class="about about2" v-for="(item, index) in list" :style="navStyle" :key="index">
        <!-- 第一级 -->
        <span @click="jump(item.target, item)">{{ item.dataName }} </span>
        <p class="dian" v-if="item.children.length > 0">·</p>

        <div class="li_box li_box2">
          <!-- 第二级 -->
          <div class="level2" v-for="(val, index) in item.children" :key="index">
            <p @click="jump(val.target, val)">{{ val.dataName }}</p>
            <i v-if="val.children.length > 0" class="el-icon-arrow-right"></i>
            <!-- 第三级 -->
            <ul class="level3">
              <li v-for="(val2, index) in val.children" :key="index">
                <p @click="jump(val2.target, val2)">{{ val2.dataName }}</p>
                <i v-if="val2.children.length > 0" class="el-icon-arrow-right"></i>
                <!-- 第四级 -->
                <ul class="level4">
                  <li @click="jump(val3.target, val3)" v-for="(val3, index) in val2.children" :key="index">
                    {{ val3.dataName }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
    <!-- <img src="~img/indexImg/011.png" class="pc_the_menu" :class="{open_menu:drawer}" v-show="!showtitle" @click="showDrawer"> -->
    <div class="pc_input_box" v-show="showtitle">
      <input class="search" type="text" style="background-color: transparent" placeholder="搜索" :class="{ mack_search: mask }" v-model="searchValue" />
      <img src="~img/indexImg/004.png" @click="search" />
    </div>
    <div class="no_of" @click="showDrawer" v-show="!showtitle">
      <div class="xian one_pc" :class="{ one_pc_animation: changeXian }"></div>
      <div class="xian two_pc" :class="{ two_pc_animation: changeXian }"></div>
      <div class="xian three_pc" :class="{ three_pc_animation: changeXian }"></div>
    </div>
    <!-- 抽屉 -->
    <el-drawer :visible.sync="drawer" direction="rtl" :modal="false" size="100%" :show-close="false">
      <img src="~img/indexImg/26.png" class="logo" />
      <div class="dra_input_box">
        <img src="~img/indexImg/013.png" class="search-img" @click="search" />
        <input type="text" v-model="searchValue" />
      </div>
      <ul class="web-list">
        <li :class="{ unfold: item.isDel }" v-for="(item, index) in webList" :key="index">
          <span @click="jump(item.target, item)">{{ item.dataName }}</span>
          <img v-if="item.children.length > 0" src="~img/indexImg/014.png" v-show="!item.isDel" @click="ChageDown(item)" class="jia" />
          <div class="img_box" v-show="item.isDel && item.children.length > 0" @click="ChageDown(item)">
            <img src="~img/indexImg/015.png" class="Reduction" />
          </div>
          <div class="drop-down">
            <div class="list_box clearfix" v-for="(val, index) in item.children" :key="index" @click="ChageDown(val)">
              <p class="leve2_text" @click="jump(val.target, val)">
                {{ val.dataName }}
              </p>
              <i class="el-icon-arrow-down" v-if="!val.isDel && val.children.length > 0"></i>
              <i class="el-icon-arrow-up" v-if="val.isDel && val.children.length > 0" @click="ChageDown(val)"></i>
              <div class="leve3" :class="{ leve3_down: val.isDel }">
                <div class="leve3_list clearfix" v-for="(val1, index) in val.children" :key="index">
                  <p class="leve3_text" @click="jump(val1.target, val1)">
                    {{ val1.dataName }}
                  </p>
                  <!-- <i class="el-icon-arrow-down" v-if="!val1.isDel && val1.children.length > 0" @click="ChageDown(val1)"></i>
                  <i class="el-icon-arrow-up" v-if="val1.isDel && val1.children.length > 0" @click="ChageDown(val1)"></i> -->
                  <div class="leve4">
                    <p v-for="(val2, index) in val1.children" :key="index" @click="jump(val2.target, val2)">
                      {{ val2.dataName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </el-drawer>
  </div>
</template>
<script>
export default {
  name: "navigtion",
  props: ["only", "w", "mask", "navlist"],
  data() {
    return {
      Animation: false,
      showInput: true,
      showtitle: true,
      drawer: false,
      changeXian: false,
      down: false,
      list: new Array(),
      webList: new Array(),
      styleHeigth: "",
      towheight: "",
      searchValue: "",
    };
  },
  created() {
    // console.log(this.mask); // 是否有遮罩  文字颜色
    if (this.mask == "NO") {
      this.navStyle = "color:#000;";
    } else {
      this.navStyle = "";
    }
    if (this.w >= 1200) {
      this.showtitle = true;
    } else if (this.w < 1200) {
      this.showtitle = false;
    }

    this.getTags();
    // console.log(this.list);
  },
  watch: {
    w: function (w) {
      // console.log(w);
      if (w >= 1280) {
        this.showtitle = true;
      } else if (w < 1280) {
        this.showtitle = false;
      }
    },
    navlist: function (value, oldValue) {
      console.log(value)
      if (value !== oldValue) {
        this.list = value;
        this.webList = this.list;
      }
    },
  },
  mounted() {
    let tiem;
    clearTimeout(tiem);
    tiem = setTimeout(() => {
      if (this.only) {
        this.Animation = true;
      }
    }, 1000);
  },
  methods: {
    async getTags() {
      const res = await this.axios.get("/v1/api/index_tags");
      if (res.code == "00") {
        console.log(res)
        localStorage.setItem("nav", JSON.stringify(res.data));
        this.list = res.data
        this.webList = res.data
        // this.navlist = res.data;
      } else {
        this.list = JSON.parse(localStorage.getItem("nav"));
        this.webList = this.list;
      }
    },
    search() {
      this.drawer = false;
      console.log(this.searchValue);
      if (this.searchValue) {
        this.$router.push({ path: `/search?wd=${this.searchValue}` });
      }
    },
    // 一级导航跳转
    ChageDown(item) {
      if (item.children.length > 0) {
        console.log(item);
        item.isDel === 0 ? (item.isDel = 1) : (item.isDel = 0);
      }
    },

    jump(target, item) {
      console.log(item);
      if (target.indexOf("http") != -1) {
        window.open(target, "_blank");
      } else if (target && target !== "#") {
        this.$router.push({
          path:
            target +
            "?dataId=" +
            item.dataId +
            "&" +
            "istitle=" +
            item.dataName,
        });
        this.drawer = false;
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    showDrawer() {
      this.drawer = this.drawer ? false : true;
      this.changeXian = this.changeXian ? false : true;
      // this.drawer = true;
    },
    chanpin() {
      window.open("https://www.armchina.com/product.html", "_blank");
    },
    zhaoxian() {
      window.open("https://app.mokahr.com/apply/armchina/885#/", "_blank");
    },
    jieshao() {
      this.$router.push({ path: "/brief" });
    },
    lianxi() {
      window.open("https://www.armchina.com/contact_us.html", "_blank");
    },
    xinwen() {
      window.open("https://www.armchina.com/activity.html", "_blank");
    },
    wenhua() {
      this.$router.push({ path: "/culture" });
    },
    lishi() {
      window.open("https://www.armchina.com/about_us.html", "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
.pc_navigtion {
  position: fixed;
  height: 115px;
  width: 19.2rem /* 1920/100 */;
  z-index: 99;
  .dise {
    width: 100%;
    height: 158px;
    background: url("~img/indexImg/30.png");
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .masks {
    width: 100%;
    height: 158px;
    background: url("~img/indexImg/31.png");
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .pc_logo {
    width: 141px;
    margin-top: 49px;
    margin-left: 0.8rem;
    position: relative;
    // z-index: 9999;
    cursor: pointer;
  }
  .ul_one {
    position: absolute;
    height: 50px;
    // width: 850px;
    top: 70px;
    right: 289px;
    // right: -9rem /* 393/100 */;
    transition: 0.5s all;
    li {
      float: left;
      margin-left: 0.3rem;
      text-align: center;
      width: 100px;
      font-weight: 300;
      color: #fff;
      transition: 0.3s all;
      cursor: pointer;
      // background: orchid;
      position: relative;
      span {
        font-size: 16px;
        transition: 0.3s all;
        padding-bottom: 8px;
        white-space: nowrap;
      }
      .dian {
        display: inline-block;
        font-size: 40px;
        // float: right;
        position: absolute;
        line-height: 0;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
      }
    }
    li:hover {
      span {
        border-bottom: 2px solid #00b3d6;
        font-size: 18px;
        white-space: nowrap;
      }
    }
    // 关于我们
    .about:hover {
      .li_box {
        height: auto;
        div {
          display: block;
        }
      }
    }
    // 关于我们
    .li_box {
      background-color: #fff;
      color: #000;
      font-size: 14px;
      height: 0;
      transition: 0.5s all;
      margin-top: 8px;
      div {
        height: 40px;
        text-align: center;
        line-height: 35px;
        display: none;
        font-size: 14px;
      }
      div:hover {
        background-color: #00b3d6;
        color: #fff;
      }
    }
    .about2 {
      position: relative;
    }
    // 产品中心
    .about2:hover {
      .li_box2 {
        height: auto;
        width: 164px;
        background-color: #fff;
        div {
          display: block;
        }
      }
    }
    // 产品中心
    .li_box2 {
      background-color: #fff;
      color: #000;
      font-size: 14px;
      height: 0;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      transition: 0.5s all;
      div {
        height: 35px;
        text-align: center;
        line-height: 35px;
        display: none;
        // display: block; /* 调试 删除 */
        font-size: 14px;
      }
      div:hover {
        background-color: #00b3d6;
        color: #fff;
        .level3 {
          opacity: 1;
          li {
            // width: 1rem;
            display: block;
          }
        }
      }
      .level2 {
        position: relative;
        i {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translate(0, -50%);
        }
        .level3 {
          position: absolute;
          left: 164px;
          top: 0;
          opacity: 0;
          li {
            background-color: #fff;
            margin-left: 0;
            color: #000;
            width: 100px;
            position: relative;
            display: none;
            .level4 {
              position: absolute;
              left: 100px;
              top: 0;
              opacity: 0;
              li {
                background-color: #fff;
                color: #000;
                width: 100px;
                display: none;
              }
              li:hover {
                background-color: #00b3d6;
                color: #fff;
                display: block;
              }
            }
          }
          li:hover {
            background-color: #00b3d6;
            color: #fff;
            .level4 {
              opacity: 1;
              li {
                // width: 1rem;
                display: block;
              }
            }
          }
        }
      }
    }
  }
  // .pc_the_menu {
  //   width: 30px;
  //   height: 20px;
  //   position: absolute;
  //   right: 0.82rem /* 82/100 */;
  //   top: 10px;
  //   cursor: pointer;
  //   transition: 0.3s all linear;
  // }
  // .open_menu {
  //   right: 0.82rem /* 82/100 */;
  // }
  .no_of {
    width: 50px;
    height: 35px;
    position: absolute;
    right: 0.8rem /* 82/100 */;
    top: 49px;
    cursor: pointer;
    z-index: 99999;
    .xian {
      width: 100%;
      height: 6px;
      background-color: #fff;
    }
    .one_pc {
      position: absolute;
      top: 0;
      transition: 0.5s all;
      transform-origin: 0%;
    }
    .one_pc_animation {
      animation: one 0.5s linear alternate forwards;
    }
    @keyframes one {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(35deg);
        background: #000;
      }
    }
    .two_pc {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      transition: 0.5s all;
    }
    .two_pc_animation {
      opacity: 0;
    }
    .three_pc {
      position: absolute;
      bottom: 0;
      transition: 0.5s all;
      transform-origin: 0%;
    }
    .three_pc_animation {
      animation: three 0.5s linear alternate forwards;
    }
    @keyframes three {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(-35deg);
        background: #000;
      }
    }
  }
  .ul_animation {
    right: 289px /* 82/100 */;
  }
  .pc_input_box {
    position: absolute;
    top: 60px;
    right: 81px;
    height: 40px;
    width: 195px /* 326/100 */;
    border-radius: 25px;
    border: 1px solid #898989;
    .search {
      width: 74%;
      height: 40px;
      padding-left: 24px;
      color: #fff;
    }
    .mack_search {
      color: #000;
    }
    img {
      width: 26px;
      height: 25px;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translate(0, -50%);
      cursor: pointer;
    }
  }
  .el-drawer__wrapper {
    .el-drawer__header {
      padding: 0;
    }
    .dra_input_box {
      width: 80%;
      margin: 25px auto;
      position: relative;
      input {
        width: 100%;
        height: 60px;
        border: 1px solid #000;
        border-radius: 60px;
        background: transparent;
        padding-left: 20px;
        font-size: 22px;
      }
      .search-img {
        position: absolute;
        width: 30px;
        top: 50%;
        right: 20px;
        transform: translate(0, -50%);
      }
    }
    .logo {
      width: 121px;
      margin-left: 91px;
      // margin-top: 10px;
    }
    .web-list {
      margin-top: 50px;
      transition: 0.5s all;
      li {
        width: 100%;
        line-height: 100px;
        padding-left: 91px;
        font-size: 26px;
        font-weight: bold;
        color: #3e3e3e;
        border-bottom: 1px solid #cdcdcd;
        position: relative;
        transition: 0.5s all;
        cursor: pointer;
        .jia {
          width: 24px;
          position: absolute;
          top: 35px;
          right: 50px;
        }
        .img_box {
          position: absolute;
          top: 20px;
          right: 20px;
          width: 50px;
          height: 50px;
          .Reduction {
            width: 24px;
            margin-top: 20px;
          }
        }
        .drop-down {
          display: none;
          height: 0;
          transition: 0.5s all;
          .list_box {
            .leve2_text {
              font-size: 16px;
              line-height: 40px;
              font-weight: 400;
              color: #686868;
              margin-left: 40px;
              float: left;
              cursor: pointer;
            }
            .leve2_text:hover {
              color: #00b3d6;
            }
            i {
              color: #000;
              font-size: 12px;
              margin-right: 50px;
              cursor: pointer;
              float: right;
            }
            .leve3 {
              margin-top: 45px;
              display: none;
              height: 0;
              // opacity: 0;
              .leve3_list {
                .leve3_text {
                  font-size: 16px;
                  line-height: 40px;
                  font-weight: 400;
                  color: #686868;
                  margin-left: 80px;
                  cursor: pointer;
                  float: left;
                }
                .leve3_text:hover {
                  color: #00b3d6;
                }
                .leve4 {
                  margin-top: 40px;
                  p {
                    font-size: 16px;
                    line-height: 40px;
                    font-weight: 400;
                    color: #686868;
                    margin-left: 100px;
                    cursor: pointer;
                  }
                  p:hover {
                    color: #00b3d6;
                  }
                }
              }
            }
            .leve3_down {
              display: block;
              height: auto;
            }
          }
        }
      }
      .unfold {
        color: #00b6da;
        transition: 0.5s all;
        height: auto;
        .drop-down {
          display: block;
          height: auto;
        }
      }
    }
  }
  /deep/.el-drawer__open .el-drawer.rtl {
    background-color: #e9e9e9;
    // background-color: transparent;
  }
  /deep/.el-drawer__body {
    overflow-y: auto;
  }
  /deep/.el-drawer__body::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px /* 10/100 */; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  /deep/.el-drawer__body::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
  }
  /deep/.el-drawer__body::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
  }
}
.zhezhao {
  background-color: #0f0f0f;
  opacity: 0.8;
}
</style>

<template>
  <div class="web_nav">
    <div class="dise" v-if="!mask"></div>
    <div class="baise" v-if="mask"></div>
    <div class="web_nbox">
      <img src="~img/indexImg/011.png" class="web-icon" @click="showPopup" v-if="mask != 'NO'" />
      <img src="~img/indexImg/logo.png" class="web-logo" @click="$router.push({ path: '/index' })" v-if="mask != 'NO'" />
      <img src="~img/indexImg/26.png" class="web-logo" @click="$router.push({ path: '/index' })" v-if="mask == 'NO'" />
    </div>

    <van-icon name="wap-nav" v-if="mask == 'NO'" @click="showPopup" />
    <van-popup v-model="show" position="right" :style="{ height: '100vh' }">
      <div class="web-search">
        <!-- <img src="~img/indexImg/26.png" class="tit_img" /> -->
        <img src="~img/indexImg/012.png" class="close" @click="show = false" />
        <input type="text" placeholder="搜索" v-model="value">
        <img src="~img/indexImg/013.png" class="search-img" @click="search" />
      </div>
      <ul class="web-list">
        <li :class="{ unfold: item.isDel }" v-for="(item, index) in list" :key="index">
          <p class="one_p" @click="jump(item.target, item)">
            {{ item.dataName }}
          </p>
          <img src="~img/indexImg/014.png" v-show="!item.isDel && item.children.length > 0" @click="changDown(item)" />
          <div class="img_box" v-show="item.isDel">
            <img src="~img/indexImg/015.png" @click="changDown(item)" class="Reduction" />
          </div>
          <div class="drop-down">
            <div v-for="(val, index) in item.children" :key="index">
              <p @click="jump(val.target, val)">{{ val.dataName }}</p>
              <div class="level3" v-for="(val2, index) in val.children" :key="index">
                <p @click="jump(val2.target, val2)">{{ val2.dataName }}</p>
                <div class="level4" v-for="(val3, index) in val2.children" :key="index">
                  <p @click="jump(val3.target, val3)">{{ val3.dataName }}</p>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      activeNames: ["1", "navlist"],
      down: false,
      list: new Array(),
      value: ""
    };
  },
  props: ["mask"],
  created() {
    this.getTags()
    // console.log(this.list);
  },
  watch: {
    navlist: function (value, oldValue) {
      if (value !== oldValue) {
        this.list = value;
      }
    },
    mask: function (value) {
      console.log(value);
    }
  },
  methods: {
    async getTags() {
      const res = await this.axios.get("/v1/api/index_tags");
      if (res.code == "00") {
        localStorage.setItem("nav", JSON.stringify(res.data));
        this.list = res.data
        // this.navlist = res.data;
      } else {
        this.list = JSON.parse(localStorage.getItem("nav"));

      }
    },
    search() {
      console.log("搜索");
      this.show = false;
      if (this.value) {
        this.$router.push({ path: `/search?wd=${this.value}` });
      }
    },
    changDown(item) {
      console.log(item);
      if (item.children.length > 0) {
        item.isDel === 0 ? (item.isDel = 1) : (item.isDel = 0);
      }
    },
    jump(target, item) {
      console.log(item);
      if (target.indexOf("http") != -1) {
        window.open(target, "_blank");
      } else if (target && target !== "#") {
        this.$router.push({
          path:
            target + "?dataId=" + item.dataId + "&" + "istitle=" + item.dataName
        });
        this.show = false;
      }
    },
    showPopup() {
      this.show = true;
    }
  }
};
</script>


<style lang="less" scoped>
.web_nav {
  height: 3.55rem /* 142/40 */;
  width: 100%;
  position: fixed;
  z-index: 999;
  .web_nbox {
    position: relative;
  }
  .dise {
    width: 100%;
    height: 80px;
    background: url("~img/indexImg/30.png");
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .baise {
    width: 100%;
    height: 80px;
    background: url("~img/indexImg/31.png");
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .web-icon {
    width: 1.1rem /* 44/40 */;
    float: right;
    margin-right: 1.25rem /* 50/40 */;
    margin-top: 1.4rem; /* 56/40 */
  }
  .web-logo {
    width: 3.975rem /* 159/40 */;
    float: left;
    margin-left: 27px /* 49/40 */;
    margin-top: 0.925rem /* 37/40 */;
  }
  .van-icon {
    font-size: 35px;
    margin-top: 0.7rem /* 37/40 */;
    float: right;
    margin-right: 1.25rem /* 50/40 */;
  }
  .van-popup {
    background-color: #e9e9e9;
    width: 100%;
    .web-search {
      height: 3.85rem /* 154/40 */;
      width: 100%;
      border-bottom: 1px solid #ababab;
      position: relative;
      .tit_img {
        width: 80px;
        position: absolute;
        top: 19px;
        left: 24px;
      }
      input {
        width: 14.425rem /* 577/40 */;
        height: 1.625rem /* 65/40 */;
        background-color: transparent;
        border: 1px solid #000;
        border-radius: 0.825rem /* 33/40 */;
        padding-left: 1rem /* 40/40 */;
        position: absolute;
        top: 50%;
        left: 1.5rem;
        transform: translate(0, -50%);
      }
      .close {
        width: 0.8rem /* 32/40 */;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 1.45rem /* 58/40 */;
      }
      .search-img {
        width: 0.875rem /* 35/40 */;
        position: absolute;
        top: 50%;
        right: 3.775rem /* 147/40 */;
        transform: translate(0, -50%);
      }
    }
    .web-list {
      li {
        height: 3rem /* 141/40 */;
        width: 100%;
        line-height: 2.5rem /* 141/40 */;
        padding-left: 1.375rem /* 55/40 */;
        font-size: 0.9rem /* 43/40 */;
        font-weight: bold;
        color: #3e3e3e;
        border-bottom: 1px solid #cdcdcd;
        position: relative;
        transition: 0.5s all;
        p {
          margin-top: 0.25rem;
        }
        .one_p {
          width: 140px;
          display: inline-block;
        }
        img {
          width: 1.025rem /* 41/40 */;
          position: absolute;
          top: 1.25rem /* 50/40 */;
          right: 1.25rem /* 50/40 */;
        }
        .img_box {
          width: 1.025rem;
          height: 1.025rem;
          position: absolute;
          top: 1.25rem /* 50/40 */;
          right: 1.25rem /* 50/40 */;
          img {
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
          }
        }
        .Reduction {
          top: 1.6rem;
        }
        .drop-down {
          height: 0;
          overflow: hidden;
          transition: 0.5s all;
          p {
            font-size: 0.75rem /* 30/40 */;
            line-height: 0.4rem /* 74/40 */;
            font-weight: 600;
            color: #686868;
            margin-bottom: 1.5rem;
          }
          .level3 {
            p {
              margin-left: 0.6rem /* 60/100 */;
              font-weight: 400;
            }
            .level4 {
              p {
                margin-left: 1.2rem /* 60/100 */;
                font-weight: 400;
              }
            }
          }
        }
      }
      .unfold {
        height: auto;
        color: #00b6da;
        .drop-down {
          height: auto;
        }
      }
    }
  }
}
</style>
<template>
  <div class="pc_footer" :class="{ pc_showfooter: showFooter }">
    <img src="~img/indexImg/logo.png" class="pc_logo" />
    <ul class="ul_title">
      <li class="pc_title">
        关于我们
        <ul>
          <li class="pc_content" @click="$router.push({ path: '/brief' })">
            公司介绍
          </li>
          <li class="pc_content" @click="$router.push({ path: '/culture' })">
            企业文化
          </li>
          <li class="pc_content" @click="$router.push({ path: '/development' })">
            发展历史
          </li>
          <li class="pc_content" @click="$router.push({ path: '/quality' })">
            质量和信息安全保障
          </li>
          <li class="pc_content" @click="$router.push({ path: '/ResourceLibrary' })">
            资源库
          </li>
        </ul>
      </li>
      <li class="pc_title">
        <!-- <a @click="$router.push({ path: '/contactus' })">
          <p class="pt">联系我们</p>
        </a> -->
        联系我们
        <ul>
          <li class="pc_content" @click="$router.push({ path: '/contactus' })">
            办公地点
          </li>
          <li class="pc_content" @click="$router.push({ path: '/agent' })">
            代理商信息
          </li>
        </ul>
      </li>
      <li class="pc_title">
        新闻中心
        <ul>
          <li class="pc_content">
            <a @click="$router.push({ path: '/infolist' })">
              <p>企业资讯</p>
            </a>
          </li>
          <li class="pc_content">
            <a @click="$router.push({ path: '/activitylist' })">
              <p>公司活动</p>
            </a>
          </li>
        </ul>
      </li>
      <li class="pc_title">
        <a href="https://app.mokahr.com/apply/armchina/885#/" target="_blank">
          <p class="pt">招贤纳士</p>
        </a>
      </li>
      <li class="pc_title">
        法律信息
        <ul>
          <li class="pc_content" @click="$router.push({ path: '/privacy' })">
            <p>安谋科技隐私政策</p>
          </li>
          <li class="pc_content" @click="$router.push({ path: '/usestandard' })">
            <p>Arm商标使用规范</p>
          </li>
        </ul>
      </li>
    </ul>
    <img src="~img/indexImg/010.jpg" class="erweima" />
    <h4>安谋科技官微</h4>
    <div class="pc_footitex">
      <span>Copyright © 2016-2022 安谋科技（中国）有限公司 &nbsp;</span>
      <a href="https://beian.miit.gov.cn/" target="_blank"> 粤ICP备18004469号</a>
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014104">
        <img style="float:left; width:20px" src="~img/indexImg/WechatIMG1446.png" alt="" />
        <span style="color:#939393;">沪公网安备31011202014104号</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'foot',
  props: ['showFooter'],
  methods: {
    falv() {
      window.open('https://www.armchina.com/trademarksGuidelines.html', '_blank')
    },
  },
}
</script>

<style lang="less" scoped>
.pc_footer {
  height: 4rem /* 400/100 */;
  width: 100%;
  position: relative;
  background-color: #0c0c0c;
  display: none;
  .pc_logo {
    width: 1.46rem /* 146/100 */;
    position: absolute;
    top: 0.64rem /* 64/100 */;
    left: 0.8rem /* 132/100 */;
  }
  .ul_title {
    position: absolute;
    top: 0.64rem /* 64/100 */;
    left: 4.88rem /* 488/100 */;
    .pc_title {
      cursor: pointer;
      float: left;
      margin-right: 0.98rem /* 98/100 */;
      color: #fff;
      font-size: 0.2rem /* 20/100 */;
      p {
        color: #777777;
      }
      .pc_content {
        // list-style: none;
        font-size: 0.16rem /* 16/100 */;
        color: #777777;
        line-height: 0.4rem /* 40/100 */;
        cursor: pointer;
      }
      .pt {
        color: #fff;
      }
    }
  }
  .erweima {
    width: 1.28rem /* 128/100 */;
    position: absolute;
    top: 1.04rem /* 104/100 */;
    right: 1.49rem /* 149/100 */;
  }
  h4 {
    font-size: 0.16rem /* 16/100 */;
    color: #fff;
    position: absolute;
    top: 2.51rem /* 251/100 */;
    right: 1.65rem /* 165/100 */;
  }
  .pc_footitex {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 0.77rem /* 77/100 */;
    background-color: #070707;
    display: flex;
    justify-content: center;
    align-items: center;
    // text-align: center;
    // line-height: 0.77rem /* 77/100 */;
    color: #777777;
    img {
      margin-left: 0.4rem;
      margin-right: 0.1rem;
    }
    a {
      color: rgb(147, 147, 147);
    }
  }
}
.pc_showfooter {
  display: block;
}
</style>

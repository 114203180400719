<template>
  <div class="web-footer">
    <ul class="web-list">
      <li :class="{ unfold: down }">
        关于我们
        <img src="~img/indexImg/27.png" v-show="!down" @click="down = true" />
        <img src="~img/indexImg/015.png" v-show="down" @click="down = false" class="Reduction" />
        <div class="drop-down">
          <p @click="$router.push({ path: '/brief' })">公司介绍</p>
          <p @click="$router.push({ path: '/culture' })">企业文化</p>
          <p @click="$router.push({ path: '/development' })">发展历史</p>
          <p @click="$router.push({ path: '/quality' })">质量和信息安全保障</p>
          <p @click="$router.push({ path: '/ResourceLibrary' })">资源库</p>
        </div>
      </li>
      <li :class="{ unfold: down3 }">
        <!-- <a @click="$router.push({ path: '/contactus' })">
          <p>联系我们</p>
        </a> -->
        联系我们
        <img src="~img/indexImg/27.png" v-show="!down3" @click="down3 = true" />
        <img src="~img/indexImg/015.png" v-show="down3" @click="down3 = false" class="Reduction" />
        <div class="drop-down">
          <p @click="$router.push({ path: '/contactus' })">办公地点</p>
          <p @click="$router.push({ path: '/agent' })">代理商信息</p>
        </div>
      </li>
      <li :class="{ unfold: down1 }">
        新闻中心
        <img src="~img/indexImg/27.png" v-show="!down1" @click="down1 = true" />
        <img src="~img/indexImg/015.png" v-show="down1" @click="down1 = false" class="Reduction" />
        <div class="drop-down">
          <a @click="$router.push({ path: '/infolist' })">
            <p>企业资讯</p>
          </a>
          <a @click="$router.push({ path: '/activitylist' })">
            <p>公司活动</p>
          </a>
        </div>
      </li>
      <li>
        <a href="https://app.mokahr.com/apply/armchina/885#/" target="_blank">
          <p>招贤纳士</p>
        </a>
      </li>
      <li :class="{ unfold: down2 }">
        法律信息
        <img src="~img/indexImg/27.png" v-show="!down2" @click="down2 = true" />
        <img src="~img/indexImg/015.png" v-show="down2" @click="down2 = false" class="Reduction" />
        <div class="drop-down">
          <a @click="$router.push({ path: '/privacy' })">
            <p>安谋科技隐私政策</p>
          </a>
          <a @click="$router.push({ path: '/usestandard' })">
            <p>Arm商标使用规范</p>
          </a>
        </div>
      </li>
    </ul>
    <div class="erwei">
      <img src="~img/indexImg/010.jpg" alt="" />
      <p>安谋科技官微</p>
    </div>

    <img class="web-bgimg" src="~img/indexImg/019.png" alt="" />
    <p class="botn">
      Copyright © 2016-2022 安谋科技（中国）有限公司<br />
      <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备18004469号</a>
    </p>
    <div style="paddingLeft:1.175rem">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014104">
        <img style="float:left; width:15px" src="~img/indexImg/WechatIMG1446.png" alt="" />
        <span style="color:#939393; fontSize:0.55rem">沪公网安备31011202014104号</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'webFooter',
  data() {
    return {
      show: false,
      down: false,
      down1: false,
      down2: false,
      down3: false,
    }
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.web-footer {
  height: 100%;
  // overflow: hidden;
  .web-list {
    width: 86%;
    margin: 50px /* 40/40 */ auto;
    position: relative;
    background-color: #000;
    z-index: 99;
    li {
      height: 60px;
      width: 100%;
      line-height: 60px /* 141/40 */;
      font-size: 16px /* 43/40 */;
      color: #fff;
      border-bottom: 1px solid #474747;
      position: relative;
      transition: 0.5s all;
      opacity: 0.7;
      p {
        color: #fff;
      }
      img {
        width: 17px /* 41/40 */;
        position: absolute;
        top: 25px /* 50/40 */;
        right: 25px /* 50/40 */;
      }
      .Reduction {
        top: 1.6rem;
      }
      .drop-down {
        height: 0;
        overflow: hidden;
        transition: 0.5s all;
        p {
          font-size: 15px /* 30/40 */;
          line-height: 37px /* 74/40 */;
          font-weight: 400;
          color: #cccccc;
        }
      }
    }
    .unfold {
      height: auto;
      color: #00b6da;
      .drop-down {
        height: auto;
      }
    }
  }
  .erwei {
    width: 105px /* 209/40 */;
    margin: 50px /* 50/40 */ auto;
    // position: absolute;
    // left: 50%;
    // bottom: 100px;
    // transform: translate(-50%);
    p {
      color: #e5e5e5;
      text-align: center;
      margin-top: 0.25rem /* 10/40 */;
    }
  }
  .web-bgimg {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .botn {
    font-size: 0.55rem /* 22/40 */;
    margin-left: 1.175rem /* 47/40 */;
    margin-top: 80px;
    color: #777777;
    position: relative;
    padding-bottom: 10px;
    a {
      color: rgb(147, 147, 147);
    }
  }
}
</style>
